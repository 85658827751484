export class AuthUser {
  public userId: string;
  public email: string;
  public userName: string;
  public displayName: string;
  public company: string;
  public roles: string[];
  public firstName: string;
  public lastName: string;

  constructor(data?: any) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          (<any>this)[property] = (<any>data)[property];
        }
      }
    }
  }
}
