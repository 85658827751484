import { animate, animateChild, group, query, style, transition, trigger, useAnimation } from '@angular/animations';
import { fadeIn, fadeOut, flash } from 'ng-animate';

export const FadeTrigger = trigger('fade', [
  transition('void => *', useAnimation(fadeIn, { params: { timing: 0.25 } })),
  transition('* => void', useAnimation(fadeOut, { params: { timing: 0 } })),
]);

export const FlashTrigger = trigger('flash', [transition('* => *', useAnimation(flash))]);

export const RouteTrigger = trigger('routeTransition', [
  transition('* => *', [
    style({ position: 'absolute' }),
    query(
      ':enter',
      [style({ transform: 'translateX(100%)' }), animate('0.4s ease-in-out', style({ transform: 'translateX(100%)' }))],
      { optional: true },
    ),
    query(
      ':leave',
      [style({ transform: 'translateX(0%)' }), animate('0.4s ease-in-out', style({ transform: 'translateX(-100%)' }))],
      { optional: true },
    ),
  ]),
]);
