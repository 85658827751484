import { Component, OnInit } from '@angular/core';
import {NavPath} from '../nav-paths/nav-path';
import {NavPaths} from '../nav-paths/nav-paths';

@Component({
  selector: 'side-nav',
  templateUrl: './side-nav.component.html'
})
export class SideNavComponent implements OnInit {
  public navPaths: NavPath[] = NavPaths;

  constructor() { }

  ngOnInit() {
  }

}
