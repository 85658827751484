<div class="profile-btn d-flex flex-row justify-content-end" [ngClass]="{'active': showProfileNav }"
     (click)="showProfileNav = !showProfileNav">
  <div class="align-self-center">
    <div>
      <div>{{userName}}</div>
      <div class="rolename">{{userEmail}}</div>
    </div>
  </div>
  <div class="user-icon align-self-center ml-2" >
    <i class="pi pi-user" style="font-size: 2.5em"></i>
  </div>
</div>
<!-- Pop up menu -->
<div class="profile-menu" [ngClass]="{'open' : showProfileNav }">
  <div class="d-flex flex-row justify-content-between button-row">
    <div class="p-2">
      <!-- Can put short text here. -->
    </div>
    <a class="pt-2 pb-2 pl-3 pr-3 sign-out-btn" (click)="signOut()">
      <i class="fa fa-sign-out-alt"></i>
      &nbsp;Sign Out
    </a>
  </div>
  <div class="pl-4 pr-4 pt-4 d-flex flex-row justify-content-between align-items-center">
    <div class="mt-2">
      <h5 class="mb-0">{{userName}}</h5>
      <h6>{{userEmail}}</h6>
    </div>
    <div>
      <div class="user-icon align-self-center ml-2" >
        <i class="pi pi-user" style="font-size: 3em"></i>
      </div>
    </div>
  </div>
  <div class="pl-4 pr-4 mt-3 mb-2">
    <a class="text-primary" [routerLink]="['/my-profile']">View Profile</a>
  </div>
</div>
