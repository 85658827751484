import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';

@Injectable()
export class AuthenticationGuard  {
  constructor(private authService: AuthService,
              private router: Router) {

  }

  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this._hasValidToken())
      return true;
    else {
      this.authService.login();
      return false;
    }
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this._hasValidToken())
      return true;
    else {
      this.authService.login();
      return false;
    }
  }

  public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this._hasValidToken();
  }

  private _hasValidToken(): boolean {
    return this.authService.isAuthenticated();
  }
}
