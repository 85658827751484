export const environment = {
  sessionTimeout: -1,
  appConfig: {
    apiBaseUrl: 'https://api-test.thebarplan.com',
    authorityUrl: 'https://identity-test.thebarplan.com',
    clientId: 'bc46b31c-5a79-4397-a248-b5d4dac7a7ad',
    clientScope: 'dashboard-api',
    uploadUrl: 'https://api-test.thebarplan.com/api/v1/files'
  },
  formIo: {
    serverUrl: 'https://test-tbp.form.io'
  },
  appInsights: {
    enabled: true,
    instrumentationKey: '3576f3fb-524f-4439-b1a4-946be3e4faf5'
  },
  features: [],
  production: false
};
