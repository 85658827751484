import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {fromPromise} from 'rxjs/internal-compatibility';
import swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private _dirtyList: string[] = [];

  public get canDeactivate(): boolean {
    // If we have nothing dirty continue thumbs up.
    return this._dirtyList.length === 0;
  }

  public addDirtyState(areaName: string) {
    // Check to see if this exists already. Only add the state once.
    if (this._findIndex(areaName) > -1)
      return;

    this._dirtyList.push(areaName);
  }

  public removeDirtyState(areaName: string) {
    // Remove the item if it exists.
    const idx = this._findIndex(areaName);
    if (idx === -1)
      return;

    this._dirtyList.splice(idx, 1);
  }

  public clear() {
    this._dirtyList = [];
  }

  public hasDirtyState(areaName: string): boolean {
    const idx = this._findIndex(areaName);
    return idx !== -1;
  }

  public promptLeaveWithoutSaving(): Observable<boolean> {
    if (this.canDeactivate)
      return of(true);

    return fromPromise(swal.fire({
      text: 'Leave without saving changes?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Leave Page',
      cancelButtonText: 'Go Back',
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      buttonsStyling: true,
      reverseButtons: false
    }).then((result) => {
      // If we the result it true clear the areas and move on.
      if (result.value) {
        this.clear();
      }
      return this.canDeactivate;
    }));
  }

  private _findIndex(areaName: string) {
    return this._dirtyList.findIndex(c => c.toLowerCase() === areaName.toLowerCase());
  }


}
