import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {OAuthModule} from 'angular-oauth2-oidc';
import {AuthInterceptorProvider} from './auth.interceptor';
import {AuthorizedGuard} from './guards/authorized.guard';
import {AuthenticationGuard} from './guards/authentication.guard';
import {AuthService} from './services/auth.service';
import {AuthLandingComponent} from './components/auth-landing.component';
import {AuthorizedDirective} from './directives/authorized.directive';
import {SessionService} from './services/session.service';
import {AuthCallbackComponent} from './components/auth-callback/auth-callback.component';
import {RouterModule} from '@angular/router';
import {authRoutes} from './auth-routing';
import {AuthLayoutComponent} from './components/auth-layout/auth-layout.component';
import {NotAuthorizedComponent} from './components/not-authorized/not-authorized.component';
import {LayoutsModule} from '../layouts/layouts.module';
import { AuthSpinnerComponent } from './shared/auth-spinner/auth-spinner.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(authRoutes),
    OAuthModule.forRoot(),
    LayoutsModule
  ],
  declarations: [
    AuthCallbackComponent,
    AuthLandingComponent,
    AuthorizedDirective,
    AuthLayoutComponent,
    NotAuthorizedComponent,
    AuthSpinnerComponent
  ],
  exports: [AuthSpinnerComponent]
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [AuthService,
        SessionService,
        AuthenticationGuard,
        AuthorizedGuard,
        AuthInterceptorProvider]
    };
  }
}
