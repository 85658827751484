import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotAuthorizedComponent implements OnInit {
  public name: string;
  public userName: string;

  constructor(private _authService: AuthService) {}

  ngOnInit() {
    const authUser = this._authService.getCurrentUser();
    this.name = authUser.displayName;
    this.userName = authUser.userName;
  }

  public signOut() {
    this._authService.signOut();
  }
}
