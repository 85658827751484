<p-toast position="top-right" key="main" [style]="{ marginTop: '60px' }"></p-toast>
<div class="site-wrapper">
  <header>
    <nav class="navbar navbar-expand navbar-light bg-light fixed-top">
      <a class="brand" [routerLink]="['']">
        <brand-logo [logoHeight]="'36px'" [logoWidth]="'auto'"></brand-logo>
        <!--    <span class="brand-text ml-3 text-uppercase">LPL</span>-->
      </a>
    </nav>
  </header>
  <div class="site-content">
    <div>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

