import {ElementRef, Injectable} from '@angular/core';
import {fromEvent, Observable, Subject} from 'rxjs';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  private _openNav = new Subject<void>();
  private _closeNav = new Subject();
  public onOpenNav = this._openNav.asObservable();
  public onCloseNav = this._closeNav.asObservable();

  constructor() { }

  public openNav() {
    this._openNav.next();
  }

  public closeNav() {
    this._closeNav.next();
  }

  public getClickEventNotInIdFilter(filters: string[]): Observable<Event> {
    return fromEvent(document, 'click').pipe(
      filter((event: any) => {
        let emit = true;
        for (const f of filters) {
          if (
            event.path.findIndex(
              i => i.id && i.id !== '' && i.id.toLowerCase() === f.toLowerCase()
            ) > -1
          ) {
            emit = false;
            break;
          }
        }
        return emit;
      })
    );
  }

  public isClickInsideElement(
    event: any,
    elRef: ElementRef,
    exceptionId: string = null
  ): boolean {
    const contains = elRef.nativeElement.contains(event.target);
    if (contains) return true;
    else if (exceptionId && exceptionId === event.target.id) return true;

    return false;
  }

  public isClickInElement(event: any, id: string) {
    if (event.target.id === id) {
      return true;
    } else if (
      event.target.parentElement &&
      event.target.parentElement.id === id
    ) {
      return true;
    }
    return false;
  }

  public isClickInElementByClass(event: any, klass: string) {
    if (event.target.classNames.findIndex(s => s === klass) > -1) {
      return true;
    } else if (event.parent.classNames.findIndex(s => s === klass) > -1) {
      return true;
    }
    return false;
  }
}
