import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable, of} from 'rxjs';
import {AuthService} from '../services/auth.service';


@Injectable()
export class AuthorizedGuard  {
  private _authPath = '/auth/not-authorized';
  constructor(
    private _authService: AuthService, private router: Router) {

  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this._canActivate(route)) {
      return true;
    }
    return this.router.parseUrl(this._authPath);
  }

  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this._canActivate(route)) {
      return true;
    }
    return this.router.parseUrl(this._authPath);
  }

  private _canActivate(route: ActivatedRouteSnapshot) {
    if (!route.data || !route.data['authorize']) {
      return of(true);
    }
    const reqRoles = route.data['authorize'] as string[];
    return of(this._authService.hasRoles(reqRoles));
  }
}
