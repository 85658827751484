import { Routes } from '@angular/router';
import { AuthCallbackComponent } from './components/auth-callback/auth-callback.component';
import { NotAuthorizedComponent } from './components/not-authorized/not-authorized.component';
import { AuthLayoutComponent } from './components/auth-layout/auth-layout.component';

export const authRoutes: Routes = [{
    path: 'auth',
    component: AuthLayoutComponent,
    children: [{
        path: 'signin-oidc',
        component: AuthCallbackComponent,
      }, {
        path: 'not-authorized',
        component: NotAuthorizedComponent,
      }
    ],
  },
];
