<nav class="navbar navbar-expand navbar-light bg-light fixed-top">
  <a class="brand" [routerLink]="['']">
    <brand-logo [logoHeight]="'36px'" [logoWidth]="'auto'"></brand-logo>
  </a>
  <ul class="navbar-nav ml-auto d-none d-md-flex">
    <li class="nav-item text-uppercase">
      <a class="nav-link" [routerLink]="['/my-forms']">My Forms</a>
    </li>
    <li class="nav-item text-uppercase" *ngIf="isSuper">
      <a class="nav-link" [routerLink]="['/my-forms/super']">Super</a>
    </li>
    <li class="nav-item text-uppercase">
      <a class="nav-link" [routerLink]="[newFormPath]">Start a New Form</a>
    </li>
    <li class="nav-item text-uppercase" *ngIf="isAdmin">
      <a class="nav-link" [routerLink]="['/isi']">ISI Config</a>
    </li>
    <li class="nav-item text-uppercase" *ngIf="isAdmin">
      <a class="nav-link" [routerLink]="['/admin']">Admin</a>
    </li>
  </ul>
  <div class="d-flex d-md-none justify-content-end ml-auto align-items-center pr-3">
    <figure class="collapse-menu burger" [ngClass]="{'open': isNavOpen}" (click)="showNavigation()">
      <span></span>
      <figcaption class="sr-only">Menu</figcaption>
    </figure>
  </div>
  <profile-nav class="d-none d-md-block"></profile-nav>
</nav>
<div class="mobile-nav" [ngClass]="{'open': isNavOpen}">
  <div class="brand">
    <brand-logo [logoHeight]="'36px'" [logoWidth]="'auto'" [onDarkBackground]="true"></brand-logo>
  </div>
  <a class="phone-link" href="tel:1-800-843-2277">
    1 (800) 843-2277
  </a>
  <menu class="list-unstyled">
    <li class="text-uppercase">
      <a class="nav-link" [routerLink]="['/my-forms']">My Forms</a>
    </li>
    <li class="text-uppercase" *ngIf="isSuper">
      <a class="nav-link" [routerLink]="['/my-forms/super']">Super</a>
    </li>
    <li class="text-uppercase">
      <a class="nav-link" [routerLink]="[newFormPath]">Start a New Form</a>
    </li>
    <li class="text-uppercase" *ngIf="isAdmin">
      <a class="nav-link" [routerLink]="['/isi']">ISI Config</a>
    </li>
    <li class="text-uppercase" *ngIf="isAdmin">
      <a class="nav-link" [routerLink]="['/admin']">Admin</a>
    </li>
  </menu>
</div>
