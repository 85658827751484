import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../auth/services/auth.service';
import {NavigationStart, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {AuthUser} from '../../auth/models/auth-user.model';

@Component({
  selector: 'profile-nav',
  templateUrl: './profile-nav.component.html'
})
export class ProfileNavComponent implements OnInit {

  public userName = '';
  public roleName = '';
  public userEmail = '';
  public showProfileNav = false;

  constructor(private _authService: AuthService, private _router: Router) { }

  ngOnInit() {
    const _authenticatedUser = this._authService.refreshAndGetAuthUser();
    if (_authenticatedUser) {
      this._initProfile(_authenticatedUser);
    }
    this._authService.onAuthUserChanged.subscribe((user: AuthUser) => {
      this._initProfile(user);
    });
    this._router.events
      .pipe(filter(evt => evt instanceof NavigationStart))
      .subscribe((evt: NavigationStart) => {
        // Close profile menu on navigation.
        this.showProfileNav = false;
      });
  }

  public signOut() {
    this._authService.signOut();
  }

  private _initProfile(user: AuthUser) {
    this.userName = user.displayName;
    this.userEmail = user.userName;
    this.roleName = this._authService.userRoles.join(',');
  }
}
