import { Component, OnInit } from '@angular/core';
import {transition, trigger, useAnimation} from '@angular/animations';
import {fadeIn} from 'ng-animate';
import {AuthService} from '../../auth/services/auth.service';

@Component({
  selector: 'private-layout',
  templateUrl: './private-layout.component.html',
  animations: [
    trigger('routeTransition', [
      transition('* => *', useAnimation(fadeIn, {params: {timing: .150}}))
    ])
  ]
})
export class PrivateLayoutComponent implements OnInit {
  public version: string;
  constructor(private _authService: AuthService) { }

  ngOnInit() {
    if (!this._authService.isAuthenticated())
      this._authService.login();
  }

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }
}
