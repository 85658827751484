import { BrowserModule } from '@angular/platform-browser';
import {ErrorHandler, InjectionToken, NgModule} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {FeaturesModule} from './features/features.module';
import {AuthModule} from './auth/auth.module';
import {LayoutsModule} from './layouts/layouts.module';
import {PageNotFoundComponent} from './pages/page-not-found.component';
import {environment} from '../environments/environment';
import {AuthorityUrl} from './auth/services/auth.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ApiBaseUrl, SubmissionTokenClient} from './services/api.client';
import {NavigationService} from './services/navigation.service';
import {CanLeaveGuard} from './guards/can-leave.guard';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ButtonModule} from 'primeng/button';
import {MonitoringErrorHandler} from './services/monitoring-error-handler';
import {CacheInterceptor} from './shared/cache-interceptor';
import { ToastrModule } from 'ngx-toastr';
import {FormioAppConfig, FormioModule} from '@formio/angular';
import { FormioResources } from '@formio/angular/resource';
import { FormioGrid } from '@formio/angular/grid';

export const FormIoBaseUrl = new InjectionToken<string>('FormIoBaseUrl');

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent
  ],
  imports: [
    FormioModule,
    FormioGrid,
    BrowserAnimationsModule,
    LayoutsModule.forRoot(),
    AuthModule.forRoot(),
    FeaturesModule.forRoot(),
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    ButtonModule,
    ToastrModule.forRoot(),
  ],
  providers: [
    {provide: FormioAppConfig, useValue: {appUrl: environment.formIo.serverUrl, apiUrl: 'https://api.form.io'}},
    {provide: ApiBaseUrl, useValue: environment.appConfig.apiBaseUrl},
    {provide: AuthorityUrl, useValue: environment.appConfig.authorityUrl},
    {provide: FormIoBaseUrl, useValue: environment.formIo.serverUrl},
    {provide: ErrorHandler, useClass: MonitoringErrorHandler},
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    FormioResources,
    CanLeaveGuard,
    NavigationService,
    SubmissionTokenClient,
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
