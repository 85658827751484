import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {NavigationService} from '../services/navigation.service';

@Injectable()
export class CanLeaveGuard  {

  constructor(private _pagenav: NavigationService) {

  }

  canDeactivate(component: any,
                currentRoute: ActivatedRouteSnapshot,
                currentState: RouterStateSnapshot,
                nextState?: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this._pagenav.promptLeaveWithoutSaving();
  }

}
