import { Component, OnInit } from '@angular/core';
import {LayoutService} from '../services/layout.service';
import {FormIds} from '../../shared/constants';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Permission } from 'src/app/auth/lookups/permissions';

@Component({
  selector: 'global-nav',
  templateUrl: './global-nav.component.html'
})
export class GlobalNavComponent implements OnInit {
  public isNavOpen = false;
  public newFormId = FormIds.NewApplication;
  public isAgent = false;
  public newFormPath = `/my-forms/new`;

  constructor(private _layoutService: LayoutService, private _authService: AuthService) { }

  ngOnInit() {
    this._authService.refreshAndGetAuthUser();
    this.isAgent = this._authService.hasRoles(['application.agency', 'application.super']);
    if (this.isAgent) {
      this.newFormPath = `/my-forms/new/on-behalf-of-client`;
    }
    this._layoutService.onOpenNav.subscribe(() => {
      this.isNavOpen = true;
    });
    this._layoutService.onCloseNav.subscribe(() => {
      this.isNavOpen = false;
    });
  }

  public showNavigation() {
    if (this.isNavOpen)
      this._layoutService.closeNav();
    else
      this._layoutService.openNav();
  }

  public get isAdmin() {
    return this._authService.hasPermission(Permission.Manage);
  }

  public get isSuper() {
    return this._authService.hasPermission(Permission.Super) || this._authService.hasPermission(Permission.Manage);
  }
}
