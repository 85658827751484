import {Injectable, OnDestroy} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, ResolveEnd, Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {AppInsights} from 'applicationinsights-js';
import {filter} from 'rxjs/operators';
import {Subscription} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MonitoringService implements OnDestroy{
  private _routeSub$: Subscription = null;

  constructor(private router: Router, private route: ActivatedRoute) {
    if (environment.appInsights.enabled && environment.appInsights.instrumentationKey) {
      AppInsights.downloadAndSetup(environment.appInsights);
    } else {
      console.log('Application insights not configured for this environment');
    }

    this._routeSub$ = this.router.events
      .pipe(filter(evt => evt instanceof ResolveEnd))
      .subscribe((evt: ResolveEnd) => {
        const comp = this._getActivatedComponent(evt.state.root);
        if (comp)
          this.logPageView(`${comp.name} ${this._getRouteTemplate(evt.state.root)}`, evt.urlAfterRedirects);
      });
  }

  public logPageView(name?: string, url?: string, properties?: {[key: string]: string},
                     measurements?: { [key: string]: number }, duration?: number) {
    AppInsights.trackPageView(name, url, this._ensureProperties(properties), measurements, duration);
  }

  public logError(error: Error, properties?: {[key: string]: string}, measurements?: { [key: string]: number }) {
    AppInsights.trackException(error, null, this._ensureProperties(properties), measurements);
  }

  public setAuthenticatedUserId(userId: string) {
    AppInsights.setAuthenticatedUserContext(userId);
  }

  private _getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if(snapshot.firstChild)
      return this._getActivatedComponent(snapshot.firstChild);

    return snapshot.component;
  }

  private _getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig)
      path += snapshot.routeConfig.path;

    if (snapshot.firstChild)
      return path + this._getRouteTemplate(snapshot.firstChild);

    return path;
  }


  private _ensureProperties(props: {[key: string]: string}): {[key: string]: string} {
    if(!props)
      props = {};

    return props;
  }

  ngOnDestroy(): void {
    if (this._routeSub$)
      this._routeSub$.unsubscribe();

  }
}
