import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders} from '@angular/common/http';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let httpHeaders = req.headers.append('Cache-Control', 'no-cache');
    httpHeaders = httpHeaders.append('Pragma', 'no-cache');
    httpHeaders = httpHeaders.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');

    const httpRequest = req.clone({
      headers: httpHeaders
    });

    return next.handle(httpRequest);
  }
}
