import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { FlashTrigger } from '../../../shared/animations';
import {OAuthService} from 'angular-oauth2-oidc';
import {switchMap} from 'rxjs/operators';
import {from} from 'rxjs';

@Component({
  selector: 'auth-callback',
  templateUrl: './auth-callback.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [FlashTrigger],
})
export class AuthCallbackComponent implements OnInit {
  constructor(private router: Router,
              private _route: ActivatedRoute,
              private _authService: AuthService,
              private _oAuthService: OAuthService) {}

  ngOnInit(): void {
    this._authService.onAuthenticated.pipe(switchMap(() => {
      const state = this._oAuthService.state;
      if (state) {
        let decodedState: string = state;
        try {
          decodedState = decodeURIComponent(state);
        } catch (e) {
          console.error(e);
        }
        return from(this.router.navigateByUrl(decodedState));
      }
      return from(this.router.navigateByUrl('/my-forms'));
    })).subscribe();
  }
}
