import {Inject, Injectable, InjectionToken} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import { OAuthEvent, OAuthService} from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import {AuthUser} from '../models/auth-user.model';
import {Router} from '@angular/router';
import {filter } from 'rxjs/operators';
import {SessionService} from './session.service';
import {Permission} from '../lookups/permissions';
import {environment} from '../../../environments/environment';
import {fromPromise} from 'rxjs/internal-compatibility';

export const AuthorityUrl = new InjectionToken<string>('AuthorityUrl');

@Injectable()
export class AuthService {
  private _authSource = new Subject();
  private _authUserChangedSub = new Subject<AuthUser>();
  private _authUser: AuthUser = null;
  private _loginRequired: any = null;

  public onAuthenticated = this._authSource.asObservable();
  public onAuthUserChanged = this._authUserChangedSub.asObservable();

  constructor(private _oauthService: OAuthService,
              private _sessionService: SessionService,
              private _router: Router,
              @Inject(AuthorityUrl) private _authorityUrl: string) {
  }

  public configureAuthentication() {
    const env = environment;
    this._oauthService.configure({
      issuer: this._authorityUrl,
      redirectUri: window.location.origin + '/auth/signin-oidc',
      silentRefreshRedirectUri: window.location.origin + '/assets/silent-refresh.html',
      postLogoutRedirectUri: window.location.origin,
      clientId: env.appConfig.clientId,
      scope: 'openid profile email ' + env.appConfig.clientScope,
      clearHashAfterLogin: false
    });

    this._oauthService.events.subscribe(e => {
    });

    this._oauthService.events
      .pipe(filter(evt => evt.type === 'token_received'))
      .subscribe(() => {
        this.buildAuthUser(this._oauthService.getIdentityClaims());
      });


    this._oauthService.events.pipe(filter(e => e.type === 'session_terminated')).subscribe(e => {
      console.log('Your session has been terminated!');
    });

    this._oauthService.loadDiscoveryDocument().then(() => {
      // This method just tries to parse the token(s) within the url when
      // the auth-server redirects the user back to the web-app
      // It doesn't send the user the the login page
      this._oauthService.tryLogin({
        onTokenReceived: (context) => {
          this.authenticated();
        }
      }).then(() => {
        if (!this._oauthService.hasValidAccessToken()) {
          console.log('No valid token');
        } else {
          this.buildAuthUser(this._oauthService.getIdentityClaims());
          this._sessionService.startSessionTimeout();
          this._sessionService.onSessionTimedOut.subscribe(() => {
            this.signOut();
          });
          this._oauthService.setupAutomaticSilentRefresh();
        }
      });
    });
  }

  public authenticated() {
    this._authSource.next();
  }

  public login() {
    this._oauthService.initCodeFlow(encodeURIComponent(this._router.url));
  }

  public refreshToken(): Observable<OAuthEvent> {
    return fromPromise(this._oauthService.silentRefresh());
  }

  public hasRoles(reqRoles: string[]): boolean {
    if (!reqRoles || !reqRoles.length)
      return true;

    if (!this.AuthUser?.roles || !this.AuthUser?.roles.length)
      return false;

    let found: boolean;
    for (const role of reqRoles) {
      const idx = this.AuthUser.roles.indexOf(role);
      found = idx > -1;
      if (found)
        break;
    }
    return found;
  }

  public get displayName(): string {
    return this.AuthUser ? this.AuthUser.displayName : '';
  }

  public get userId(): string {
    return this.AuthUser ? this.AuthUser.userId : null;
  }

  public get userRoles(): string[] {
    return this.AuthUser ? this.AuthUser.roles : [];
  }

  public getBearerToken(): string {
    const token = this._oauthService.getAccessToken();
    if (!token) {
      return null;
    }
    return token;
  }

  public getCurrentUser(): AuthUser {
    return this.AuthUser;
  }

  public getUserRoles(): string[] {
    return this.AuthUser ? this.AuthUser.roles : [];
  }

  public signOut() {
    this._oauthService.logoutUrl = this._buildLogoutUrl();
    this._oauthService.logOut(false);
  }

  public isAuthenticated(): boolean {
    return this._oauthService.hasValidAccessToken();
  }

  public hasPermission(permission: Permission): boolean {
    const userPermissions = this.getUserRoles();
    return userPermissions.includes(permission);
  }

  public hasAnyPermission(permissions: Permission[]): boolean {
    const userPermissions = this.getCurrentUser().roles;
    let found: boolean;
    for (const r of permissions) {
      found = userPermissions.includes(r);
      if (found)
        break;
    }
    return found;
  }

  private get AuthUser(): AuthUser {
    return this._authUser;
  }

  private buildAuthUser(identityClaims: any): AuthUser {
    const user = new AuthUser();
    user.userId = identityClaims.id;
    user.userName = identityClaims.preferred_username;
    user.company = identityClaims.company;
    user.email = identityClaims.email;
    user.firstName = identityClaims.given_name ? identityClaims.given_name : '';
    user.lastName = identityClaims.family_name ? identityClaims.family_name : '';
    user.displayName = `${user.firstName} ${user.lastName}`;

    user.roles = (identityClaims.role instanceof Array)
      ? identityClaims.role
      : [identityClaims.role];
    this._authUserChangedSub.next(user);
    return user;
  }

  private _buildLogoutUrl(): string {
    return `${this._authorityUrl}/auth/logout?returnUrl=${window.location.href}`;
  }


  public refreshAndGetAuthUser() {
    this._authUser = this.buildAuthUser(this._oauthService.getIdentityClaims());
    return this._authUser;
  }
}
