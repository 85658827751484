import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {FeatureService} from '../services/feature.service';

@Directive({
  selector: '[feature]',
})
export class FeatureDirective {
  constructor(private _featureService: FeatureService,
              private viewRef: ViewContainerRef,
              private templateRef: TemplateRef<any>) { }

  @Input()
  set feature(featureName: string) {
    if (this._featureService.isFeatureEnabled(featureName)) {
      this.viewRef.createEmbeddedView(this.templateRef);
    } else {
      this.viewRef.clear();
    }
  }
}
