import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureDirective } from './directives/feature.directive';
import {FeatureService} from './services/feature.service';

@NgModule({
  declarations: [FeatureDirective],
  exports: [FeatureDirective],
  imports: [
    CommonModule
  ]
})
export class FeaturesModule {
  static forRoot(): ModuleWithProviders<FeaturesModule> {
    return {
      ngModule: FeaturesModule,
      providers: [FeatureService]
    };
  }
}
