import {Component, OnInit} from '@angular/core';
import {AuthService} from '../services/auth.service';

@Component({
  selector: 'auth-landing',
  templateUrl: './auth-landing.component.html',
  styleUrls: ['./auth-landing.scss']
})
export class AuthLandingComponent implements OnInit {

  constructor() {

  }

  ngOnInit(): void {

  }
}
