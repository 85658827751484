import {Component, Input, OnInit} from '@angular/core';
import {NavPath} from '../nav-paths/nav-path';
import {NavPaths} from '../nav-paths/nav-paths';
import {AuthService} from '../../auth/services/auth.service';
import {FeatureService} from '../../features/services/feature.service';

let _navInstanceId = 0;

@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit {
  @Input() public listClass = '';
  @Input() public itemClass = '';
  @Input() public linkClass = '';
  @Input() public ddLinkClass = '';
  @Input() public ddMenuClass = '';
  @Input() public chevronClass = '';

  private _navPaths: NavPath[] = NavPaths;
  public navPaths: NavPath[];
  public navId = `nav-${_navInstanceId++}`;

  constructor(private _authService: AuthService,
              private _featureService: FeatureService) {
    this.navId = `nav-${_navInstanceId++}`;
  }

  ngOnInit(): void {
    this.navPaths = this._navPaths.filter((p: NavPath) => {
      if (!this._featureService.isFeatureEnabled(p.feature))
        return false;

      if (p.visible === false)
        return false;

      if(p.permissions && p.permissions.length)
        return this._authService.hasAnyPermission(p.permissions);

      return true;
    });
  }

}
