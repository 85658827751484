import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthenticationGuard} from './auth/guards/authentication.guard';
import { AuthorizedGuard } from './auth/guards/authorized.guard';
import {PrivateLayoutComponent} from './layouts/private-layout/private-layout.component';
import {PageNotFoundComponent} from './pages/page-not-found.component';

const routes: Routes = [
  {path: '', redirectTo: '/my-forms', pathMatch: 'full'},
  {
    path: '',
    component: PrivateLayoutComponent,
    canActivateChild: [AuthenticationGuard],
    children: [{
        path: 'my-forms',
        loadChildren: () => import('./lpl-application/lpl-application.module').then(m => m.LplApplicationModule)
      }, {
        path: 'my-profile',
        loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
      }, {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
        canActivate: [AuthorizedGuard],
        data: {
          'authorize': ['application.manage']
        }
      }, {
        path: 'isi',
        loadChildren: () => import('./isi/isi.module').then(m => m.ISIModule),
        canActivate: [AuthorizedGuard],
        data: {
          'authorize': ['application.manage']
        }
      }],
  }, {
    path: 'storage',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./storage/storage.module').then(m => m.StorageModule)
  },
  {
    path: 'aop',
    loadChildren: () => import('./aop/aop.module').then(m => m.AopModule)
  },
  {
    path: 'agency',
    loadChildren: () => import('./agency/agency.module').then(m => m.AgencyModule)
  },
  {
    path: 'renewal',
    loadChildren: () => import('./renewal/renewal.module').then(m => m.RenewalModule)
  },
  {
    path: '**', component: PageNotFoundComponent
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
