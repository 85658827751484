import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'brand-logo',
  templateUrl: './brand-logo.component.html'
})
export class BrandLogoComponent implements OnInit {
  @Input() public logoHeight = '36px';
  @Input() public logoWidth = 'auto';
  @Input() onDarkBackground = false;

  constructor() { }

  ngOnInit() {
  }

}
