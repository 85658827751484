import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Feature} from '../models/feature';


@Injectable()
export class FeatureService {
  private readonly _features: Feature[];

  constructor() {
    this._features = <Feature[]> environment.features;
  }

  /** Check if a feature is enabled pass the name of the feature. If nested then dot the features. Ex: feature.child **/
  public isFeatureEnabled(featureName: string): boolean {
    let enabled = true;
    if (!featureName || featureName.length === 0) {
      console.error('isFeatureEnabled: Invalid argument featureName is required');
      return false;
    }
    const checks = featureName.split('.');
    for (const name of checks) {
      if (!this.checkForFeature(name, this._features)) {
        enabled = false;
        break;
      }
    }
    return enabled;
  }

  private checkForFeature(name: string, features: Feature[]): boolean {
    let enabled = true;
    for (const f of features) {
      if (f.name.toLowerCase() === name.toLowerCase() && !f.enabled) {
        enabled = false;
        break;
      }

      if (f.features && f.features.length) {
        if (!this.checkForFeature(name, f.features)) {
          enabled = false;
          break;
        }
      }
    }
    return enabled;
  }
}
