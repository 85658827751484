import {Permission} from '../../auth/lookups/permissions';
import {NavPath} from './nav-path';

export const NavPaths: NavPath[] = [{
  title: 'Dashboard',
  route: ['/dashboard'],
  visible: true,
  feature: 'dashboard',
  iconClass: 'chart-line',
  permissions: [Permission.Administrator]
}];
