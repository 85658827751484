<div class="site-wrapper">
  <header>
    <nav class="navbar navbar-expand navbar-light bg-light fixed-top">
      <a class="brand" [routerLink]="['']">
        <brand-logo [logoHeight]="'36px'" [logoWidth]="'auto'"></brand-logo>
        <!--    <span class="brand-text ml-3 text-uppercase">LPL</span>-->
      </a>
    </nav>
  </header>
  <div class="site-content">
    <div>
      <div class="container mt-4 p-4">
        <h2 class="text-uppercase pt-4">
          401 Not Authorized
        </h2>
        <p>{{ name }} ({{ userName }}): Sorry you are not authorized to access a section of the application (The BarPlan LPL Application Dashboard).</p>
        <p>Please contact your administrator to obtain access.</p>
        <p>
          <a class="text-primary c-pointer" (click)="signOut()">
            <i class="fas fa-sign-out-alt"></i> Sign out and login with another account
          </a>
        </p>
      </div>

    </div>
  </div>
</div>








