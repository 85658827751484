<ul [id]="navId" [class]="'clearfix ' + listClass">
  <ng-container *ngFor="let path of navPaths; index as i">
    <li [class]="itemClass" *ngIf="!path.children || path.children.length === 0">
      <a [class]="linkClass" [routerLink]="path.route" routerLinkActive="active">
        <span class="link-text d-flex flex-row align-items-center">
          <i [ngClass]="path.iconClass" class="fa fa-xl"></i>
          <div class="ml-2" style="top: -1px; position: relative;">{{path.title}}</div>
          <span *ngIf="path.showAlert" class="alert alert-danger"></span>
        </span>
      </a>
    </li>
    <li [class]="itemClass" *ngIf="path.children && path.children.length > 0">
      <a [class]="linkClass + ' ' + ddLinkClass" data-toggle="collapse" [attr.data-parent]="'#' + navId"
         [href]="'#' + navId + '-n-menu' + i"
         routerLinkActive #rla="routerLinkActive" [ngClass]="{'active': rla.isActive }">
                  <span class="link-text">
                    <i [ngClass]="path.iconClass" class="icon fa"></i>
                    {{path.title}}
                    <span *ngIf="path.showAlert" class="alert alert-danger"></span>
                    <span [class]="chevronClass ? chevronClass : 'chevron'"></span>
                  </span>
      </a>
      <ul [class]="'collapse ' + ddMenuClass" routerLinkActive="show" [id]="navId + '-n-menu' + i">
        <li [class]="itemClass" *ngFor="let child of path.children">
          <a [class]="linkClass" [routerLink]="child.route" routerLinkActive="active">
              <span class="link-text">
                <i [ngClass]="child.iconClass" class="fa fa-xl"></i> {{child.title}}
                  <span *ngIf="child.showAlert" class="badge badge-pill badge-danger">3</span>
              </span>
          </a>
        </li>
      </ul>
    </li>
  </ng-container>
</ul>
