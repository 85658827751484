<p-toast position="top-right" key="main" [style]="{ marginTop: '60px' }"></p-toast>
<div class="site-wrapper">
  <header>
    <global-nav></global-nav>
  </header>
  <div class="site-content">
    <div>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

