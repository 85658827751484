import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrivateLayoutComponent } from './private-layout/private-layout.component';
import {RouterModule} from '@angular/router';
import { GlobalNavComponent } from './global-nav/global-nav.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import {LayoutService} from './services/layout.service';
import { ProfileNavComponent } from './profile-nav/profile-nav.component';
import { NavigationComponent } from './navigation/navigation.component';
import {MessageService} from 'primeng/api';
import { BrandLogoComponent } from './brand-logo/brand-logo.component';
import {ToastModule} from 'primeng/toast';
import { SimpleLayoutComponent } from './simple-layout/simple-layout.component';

@NgModule({
  declarations: [
    PrivateLayoutComponent,
    GlobalNavComponent,
    SideNavComponent,
    ProfileNavComponent,
    NavigationComponent,
    BrandLogoComponent,
    SimpleLayoutComponent],
  imports: [
    RouterModule,
    CommonModule,
    ToastModule
  ],
  exports: [
    GlobalNavComponent,
    SideNavComponent,
    ProfileNavComponent,
    NavigationComponent,
    BrandLogoComponent,
    SimpleLayoutComponent
  ]
})
export class LayoutsModule {
  static forRoot(): ModuleWithProviders<LayoutsModule> {
    return {
      ngModule: LayoutsModule,
      providers: [LayoutService, MessageService]
    };
  }
}
